<template>
  <v-container style="max-width: 800px">
    <v-card>
      <v-card-title>
        <h4 class="text-center w-full break">
          TERMOS E CONDIÇÕES DE USO E ACEITE DE COMPRA
        </h4>
      </v-card-title>
      <v-card-text class="px-6">
        <div class="gap-3 align-center pb-9">
          <span
            class="text-overline lh-1 font-weight-bold text-10 text--lighten-2 mb-0"
          >
            {{ legalInfo.legalName }}
          </span>
          <h5 class="font-weight-bold text--lighten-2 mb-0">
            {{ legalInfo.fantasyName }}
          </h5>
          <span class="text--lighten-2 text-15">
            {{ legalInfo.document }}
          </span>
        </div>

        <p>
          Estes Termos apresentam as Condições Gerais aplicáveis às compras e ao
          uso dos serviços oferecidos pela {{ legalInfo.fantasyName }}, empresa
          devidamente inscrita no CNPJ/MF sob o n° {{ legalInfo.document }},
          aqui denominada Plataforma de Venda de Ingressos, por qualquer pessoa
          que deseje utilizar tais serviços, doravante nominada “Usuário”.
        </p>
        <p>
          Os termos aqui expostos atenderão os princípios e normas segundo o
          Código de Defesa do Consumidor, Lei nº 8.078 de 11 de setembro de
          1990, entre outras disposições previstas no Código Civil Brasileiro,
          Lei nº 10.406 de 10 de janeiro de 2002 e pela Lei Geral de Proteção de
          Dados Pessoais, ou Lei 13709 de 14 de agosto de 2018.
        </p>
        <p>
          Para darmos sequência à sua compra, solicitamos que leiam e aceitem os
          termos a seguir:
        </p>
        <ol>
          <li>
            A Plataforma de Venda de Ingressos não promove ou organiza eventos,
            sendo contratada por promotores de eventos para operacionalizar a
            venda de seus ingressos.
          </li>
          <li>
            A Plataforma de Venda de Ingressos é responsável somente pelo
            serviço de venda de ingressos referente aos eventos divulgados,
            estando completamente isenta de qualquer responsabilidade referente
            a organização e realização do evento.
          </li>
          <li>
            Os valores dos ingressos estão sujeitos à alteração sem aviso prévio
            e válidos até o término do lote. Informamos em cumprimento ao Art. 6
            da lei 8.078/90, que conforme a venda de ingressos for ocorrendo,
            novos lotes poderão ser colocados à venda, com outros valores, sem
            necessidade de aviso prévio.
          </li>
          <li>
            O Usuário é responsável pelo preenchimento correto de seu cadastro
            (dados pessoais), bem como por verificar todas as informações
            pertinentes ao seu pedido (evento, dia, horário, número de
            ingressos, informações sobre assentos, setor, classificação etária)
            antes de concluir sua compra.
          </li>
          <li>
            A finalização da compra dos ingressos está sujeita à disponibilidade
            de lugares no sistema, bem como à aprovação da administradora do
            cartão.
          </li>
          <li>
            Os pedidos de compra estão condicionados à confirmação do pagamento.
            O Usuário declara conhecer e aceitar que as informações solicitadas
            sejam utilizadas para confirmar a veracidade dos dados do comprador
            para aprovação da compra.
          </li>
          <li>
            Ao realizar a compra, o Usuário concorda com a coleta, uso e
            divulgação de suas informações pessoais conforme estabelecido na
            Política de Privacidade da Plataforma, que pode ser acessada no site
            da Plataforma de Venda de Ingressos sendo incorporada a este Termo.
          </li>
          <li>
            O evento poderá ser filmado e/ou fotografado pelo promotor e/ou
            patrocinadores do evento para utilização em qualquer veículo de
            comunicação, e o portador do ingresso cede e autoriza a utilização
            gratuita de sua imagem e voz, sem que isso caracterize uso indevido
            de imagem.
          </li>
          <li>
            A Plataforma de Venda de Ingressos não se responsabiliza sob
            hipótese nenhuma, por ingressos adquiridos fora dos canais de venda
            oficiais: bilheteria, pontos de venda, aplicativo e site oficial da
            plataforma de venda de ingressos.
          </li>
          <li>
            A duplicação ou revenda do Ingresso / Bilhete Electrónico podem
            impedir o livre acesso ao recinto, fato que a Plataforma de Venda de
            Ingressos é isenta de qualquer responsabilidade.
          </li>
          <li>
            A disponibilidade de ingressos de meia-entrada ou de outros
            descontos está sujeita às regras estabelecidas pela Promotora do
            Evento e/ou pelo local onde o evento será realizado, sempre
            garantidas as disposições de lei.
          </li>
          <li>
            É imprescindível a apresentação do documento comprobatório do
            beneficiário adquirente de MEIA ENTRADA no acesso ao evento.
          </li>
          <li>
            Não serão efetuadas trocas e/ou devoluções das compras feitas
            diretamente nos pontos de venda físico, exceto no caso de o evento
            sofrer alguma alteração ao previsto ou ser cancelado.
          </li>
          <li>
            Compras realizadas pelo site poderão ser canceladas no prazo de 7
            dias, aos termos do Código de Defesa do Consumidor, contando com a
            data da compra, o cancelamento deverá ser solicitado no prazo máximo
            de até 48 horas antes da abertura dos portões do evento.
          </li>
          <li>
            A solicitação de cancelamento deve ser formalizada pelo e-mail
            {{ email }} dentro do prazo estipulado no item acima.
          </li>
          <li>
            É de responsabilidade do Usuário, a inutilização do ingresso após a
            confirmação do cancelamento, ficando este indisponível para a
            entrada no evento.
          </li>
          <li>
            O reembolso ao Usuário referente ao cancelamento de compras on-line
            ocorrerá de forma integral por depósito bancário ou crédito no
            cartão, no prazo de até 90 (noventa) dias da data da solicitação de
            cancelamento.
          </li>
          <li>
            Não é possível efetuar o cancelamento parcial de ingressos
            adquiridos através de um mesmo "pedido" tendo em vista que o
            pagamento On line é processado baseado no valor total do "pedido",
            tornando-se, portanto, operacionalmente impossível cancelar junto
            aos processadores de pagamentos de forma parcial.
          </li>
          <li>
            Caso o Usuário proceda o pedido de estorno da compra efetuada
            diretamente com a operadora do cartão de crédito, sem o
            consentimento da Plataforma de Venda de Ingressos, fica o Usuário
            ciente de que a Plataforma de Venda de Ingressos poderá utilizar dos
            meios judiciais cabíveis para reaver os valores devidos,
            especialmente, em casos de configuração de fraude.
          </li>
          <li>
            O contratante dos serviços (promotor do evento) é o único e
            exclusivo responsável pela realização, cancelamento e adiamento do
            evento/espetáculo, bem como pela eventual troca ou restituição do
            valor do ingresso.
          </li>
          <li>
            Todos os conteúdos e preços descritos e apresentados no site são da
            responsabilidade da Entidade Promotora do Evento, podendo ser
            modificados a qualquer momento, e sem aviso prévio, pela mesma.
          </li>
          <li>
            Sobre o valor total da transação poderá ser acrescido custos de
            operação e taxa de serviço, ou taxa de conveniência, consoante o
            evento escolhido.
          </li>
          <li>
            Não é permitido exibir, reproduzir, modificar ou transmitir os
            conteúdos desse site, seja com o propósito público ou comercial, sem
            o prévio e expresso consentimento por escrito da Plataforma de Venda
            de Ingressos.
          </li>
          <li>
            Este site pode conter links para sites de terceiros, fornecidos
            unicamente para conveniência e acessibilidade do usuário. A
            Plataforma de Venda de Ingressos não endossa e não é responsável
            pelo conteúdo de qualquer site de terceiros, sendo que o acesso a
            tais sites será conduzido por inteiro risco do Usuário.
          </li>
        </ol>
        <p>
          Dúvidas entre em contato através do e-mail
          {{ email }} ou atendimento SAC WhatsApp {{ whatsapp }}.
        </p>
        <div class="d-flex justify-start">
          <a href="/terms/privacy">
            <span>Veja também a política de privacidade.</span>
          </a>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { legalInfo } from "@/themeConfig.js";
import { email } from "@/themeConfig.js";
import { whatsapp } from "@/themeConfig.js";

export default {
  data: () => ({
    legalInfo,
    email,
    whatsapp,
  }),
};
</script>

<style>
.break {
  word-break: keep-all;
  overflow-wrap: break-word;
  hyphens: auto;
}

@media (max-width: 490px) {
  .break {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1rem;
  }
}

li {
  margin-bottom: 1rem;
  text-align: justify;
}

p {
  text-align: justify;
}
</style>
